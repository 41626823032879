Vue.component('suggestionListComponent', {
    data: function () {
        return {
            orderSelected:null,
            suggestionsList : [],
            launchBackground:true,
        }
    },
    watch: {
        '$store.state.appState':'fetchData',
        '$store.state.suggestionList':'fetchData',
        '$store.state.orderBy':'fetchData',
        '$store.state.orderDirection':'fetchData',
        'initItem':'fetchData',
        'lastItem':'fetchData'
    },
    computed: {
        viewmode: function(){
            return this.$store.getters.getItemViewMode;
        },
        initItem: function () {
            return this.$store.getters.getItemPerPage * this.$store.getters.getOffset;
        },
        lastItem: function () {
            return this.$store.getters.getItemPerPage * this.$store.getters.getOffset + this.$store.getters.getItemPerPage;
        },
        moduleObject: function () {
            return {
                'green-text': this.viewmode == 'module'
            }
        },
        listObject: function () {
            return {
                'green-text': this.viewmode == 'list'
            }
        }
    },
    methods:{
        fetchData:function(){
            let self = this;
            this.suggestionsList=[];
            let suggestionsList=[];
            let itemWithOutPrice = [];

            if(!this.orderSelected)
                for(let orderField of this.$store.getters.getSetting.ItemOrdered){
                    if(orderField.fields.ByDefault){
                        this.orderSelected = orderField.fields.internalId
                    }
                }

            for(let itemCode of this.$store.getters.getSuggestions){
                let itemLoad = this.$store.getters.getItemByCode(itemCode);
                if(itemLoad)
                    suggestionsList.push(itemLoad);
            }

            suggestionsList = __lodash__.orderBy(suggestionsList, this.$store.getters.getOrderBy, this.$store.getters.getOrderDirection);

            for(let itemLoad of suggestionsList.slice(this.initItem,this.lastItem)){
                if(!itemLoad.isCalculate){
                    itemWithOutPrice.push(itemLoad);
                }
            }

            //console.log('Item With Out Price',itemWithOutPrice.length,this.initItem,this.lastItem);

            if(itemWithOutPrice.length>0){
                this.$store.dispatch('updatePrices', itemWithOutPrice).then(() => {
                    if(self.$store.getters.getOrderBy=="Price")
                        self.suggestionsList = __lodash__.orderBy(suggestionsList, self.$store.getters.getOrderBy, self.$store.getters.getOrderDirection);
                    else
                        self.suggestionsList = suggestionsList
                });
            }
            else {
                this.suggestionsList = suggestionsList;
            }
            if(this.launchBackground) {
                itemWithOutPrice = [];
                for(let itemLoad of suggestionsList.slice(this.lastItem)){
                    if(!itemLoad.isCalculate){
                        itemWithOutPrice.push(itemLoad);
                    }
                }
                //console.log('Must send BackGround',this.launchBackground,itemWithOutPrice);
                this.$store.dispatch('updatePricesBackGroup', itemWithOutPrice);
                this.launchBackground=false;
            }

        },
        openItemView:function(item){
            if(item)
                this.$router.push(`/view/${item.Code}`);
        },
        getItemByCode:function(itemCode){
            return this.$store.getters.getItemByCode(itemCode);
        },
        reorderList: function () {
            this.$store.dispatch('reorderItems',this.orderSelected);
        },
        changeViewMode:function(viewMode){
            this.$store.dispatch('changeItemViewMode',viewMode)
        }
    },
    mounted:function(){
        if(!store.getters.getSession){
            this.$router.push({"name":"HomeLink"})
        }
        else
            this.fetchData()
    },
    template: `<div class="produktliste">
                    <div class="header-produktliste">
                        <h4>{{tr('Suggested List')}} ({{suggestionsList.length}})</h4>
                         <div class="order-options">
                            <span>{{tr('Order by')}}</span>
                            <select id="soflow" v-model="orderSelected" class="minimal browser-default" @change="reorderList">
                                <option disabled value="">{{tr('Select One')}}</option>
                                <template v-for="orderField of $store.getters.getSetting.ItemOrdered">
                                    <option :value="orderField.fields.internalId" :selected="orderField==orderSelected">
                                        {{tr(orderField.fields.ItemField)}}
                                        ({{orderField.fields.OrderDirection}})
                                    </option>
                                </template>
                            </select>
                        </div>
                        <div class="view-options">
                            <span @click="changeViewMode('module')" v-bind:class="moduleObject">
                                <span>Mosaico</span>
                                <i class="fas fa-th-large fa-1x"></i>
                            </span>
                            <span @click="changeViewMode('list')" v-bind:class="listObject">
                                <span >Lista</span>
                                <i class="fas fa-list-alt fa-1x"></i>
                            </span>
                        </div>
                        <template v-if="suggestionsList.length>0">
                            <paginationComponent key="suggestion-pagination-top" currentView="suggestions" :items="suggestionsList" v-if="suggestionsList.length>0"></paginationComponent>
                        </template>
                    </div>
                    <div class="produktliste">
                         <template v-if="$store.getters.getAppState!='ready'">
                                <template v-if="filteredItems.length==0">
                                    <div class="container center section white" >
                                        <h3>{{tr('Please wait. Loading ...')}}</h3>
                                    </div>
                                </template>
                         </template>
                         <template v-else>
                            <template v-for="item in suggestionsList.slice(initItem,lastItem)">
                                <div :class="{col:true, 's12': viewmode=='list', 's6 m4 l3': viewmode=='module'}" >
                                      <template v-if="viewmode=='list'">
                                          <itemViewListComponent :item="item" @click="openItemView(item)"></itemViewListComponent>
                                      </template>
                                      <template v-else>
                                          <itemViewModuleComponent :item="item" @click="openItemView(item)"></itemViewModuleComponent>
                                      </template>
                                </div>
                            </template>
                        </template>
                    </div>
                     <div class="header-produktliste">
                        <template v-if="suggestionsList.length>0">
                            <paginationComponent key="suggestion-pagination-bottom" currentView="suggestions" :items="suggestionsList" v-if="suggestionsList.length>0"></paginationComponent>
                        </template>
                    </div>
                </div>`
});